import { InjectionToken } from '@angular/core';
export const environment = {
  production: true,
  version: "0.0.0.0",
  B2CConfigs: {
    BackendURL: 'https://cpqportal-dev.dovertech.co.in/api',
    BlobStorageURL: 'http://entitystorage.blob.core.windows.net',
    DefaultBlobId: 'private',
    ImageBackendUrl: 'https://cpqportal-dev.dovertech.co.in/api',
    b2cScopes:'https://cxportaluat.onmicrosoft.com/f52646c5-4976-49d9-b25c-61f4036f7aff/user.read',
    clientId: 'f52646c5-4976-49d9-b25c-61f4036f7aff',
    isPolicy: true,
    redirectUrl: 'https://cpqportal-dev.dovertech.co.in',
    superAdminOid: '60e13db6-5238-4999-b2c6-d723bb9da9d8',
    tenant: 'cxportaluat.onmicrosoft.com',
    authURL: "https://cxportaluat.b2clogin.com/tfp",
    signinPolicy: {
      TWG: 'b2c_1a_cxportal_uat_signin_policy_twg',
      VWS: 'B2C_1A_signup_signin-CPQ-VWS',
    },
    resetPasswordPolicy: {
      TWG: 'B2C_1A_PasswordResetTWG',
      VWS: 'B2C_1A_PasswordReset-CPQ-VWS'
    },
    changePasswordPolicy: {
      TWG: 'B2C_1A_PasswordChangeTWG',
      VWS: 'B2C_1A_ProfileEditPasswordChange-CPQ-VWS'
    },
    b2cLoginURL: 'https://cxportaluat.b2clogin.com',
    passwordChangeSuccessUrl: 'https://cpqportal-dev.dovertech.co.in/changePasswordSuccess',
    cxPortal: {
      baseUrl:'https://customerportal-uat.dovertwg.com'
    }
  },
  fpx: {

  },
  cds: {
    endpoint: 'qa',
  },
  dataHubUrl: 'https://apidev.dovercorporation.com/oneview/customer?type=abstract&opco=twg&subscription-key=6f73e387053b4a5182033d4ff452bb4b&_boomi_debug=true'
};